import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { When } from '../styling/when'
import { Check } from '../components/icons'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ThinArrow = props => {
  return (
    <svg
      width={93}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_thin_arrow)">
        <path
          d="M89.97 6.91H.75M7.6 13.08l-6.67-6a.22.22 0 010-.33l6.67-6"
          stroke="#AF8D08"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.97 9a2.09 2.09 0 100-4.18 2.09 2.09 0 000 4.18z"
          fill="#AF8D08"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_thin_arrow">
          <path fill="#fff" d="M0 0h92.05v13.83H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Container = styled.div`
  background-color: #212121;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', 'Noto Sans Georgian';
  * {
    font-family: 'Poppins', 'Noto Sans Georgian' !important;
    text-align: center;
  }
`

const PostMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

  ${When.Large} {
    max-width: 300px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

const Round = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 12px;
  background-color: #4dae50;

  display: flex;
  justify-content: center;
  align-items: center;
`

const YourMessageWasSent = styled.h3`
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  color: white;
  text-align: center;
  ${When.Large} {
    font-size: 24px;
    margin-bottom: 12px;
  }
`

const WeWillGetInTouch = styled.p`
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  color: white;

  ${When.Large} {
    font-size: 14px;
    margin-bottom: 12px;
  }
`

const SmallerText = styled.p`
  font-size: 10px;
  margin: 0;
  padding: 0;
  ${When.Large} {
    font-size: 12px;
  }
`
const MessageSentSendBox = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
`

const StyledSendButton = styled.button`
  margin-top: 15px;
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 14px;
  font-size: 14px;
  cursor: pointer;
  & span {
    color: #fff;
  }

  & svg path {
    stroke: #fff;
  }

  & svg path:nth-of-type(2) {
    fill: #fff;
  }
`

const StyledThinArrow = styled(ThinArrow)`
  transform: rotate(180deg);
`

const SECONDS_BEFORE_REDRECT = 7

export default function ThankYouPage() {
  const [secondsBeforeRedirect, setSecondsBeforeRedirect] = useState(
    SECONDS_BEFORE_REDRECT
  )
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setInterval(() => {
      setSecondsBeforeRedirect(previous => previous - 1)
    }, 1000)
  }, [])

  useEffect(() => {
    if (secondsBeforeRedirect === 0) {
      history.back()
    }
  }, [secondsBeforeRedirect])

  const onSendAgain = () => {
    history.back()
  }

  return (
    <Container>
      <PostMessageWrapper>
        <Round>
          <Check />
        </Round>
        <YourMessageWasSent>{t('MessageSent')}</YourMessageWasSent>
        <WeWillGetInTouch>
          {t('MessageSent2')}
          <SmallerText>
            {t('Redirect')} <b>{secondsBeforeRedirect}</b> {t('Seconds')}
          </SmallerText>
        </WeWillGetInTouch>
        <MessageSentSendBox>
          <StyledSendButton onClick={onSendAgain}>
            <span>{t('MessageSent3')}</span>
            <StyledThinArrow />
          </StyledSendButton>
        </MessageSentSendBox>
      </PostMessageWrapper>
    </Container>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
